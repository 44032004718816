define('Axelites_Core/js/axQuickView',[
    "jquery",
    "mage/translate",
    "Magento_Ui/js/modal/modal",
    "js/jquery.countdown.min",
    "Magento_Customer/js/customer-data",
    "js/swiper",
    "Axelites_Core/js/model/ax-core",
    "Magento_Ui/js/view/messages",
    "domReady!",
    "Magento_Catalog/js/catalog-add-to-cart"
], function ($, $t,modal,countdown,customerData,axCore) {

    var catalogAddToCartWidget = $.mage.catalogAddToCart('instance');
    const typesenseConfig = typesense_config_data.typesenseConfig;
    const storeId = typesense_config_data.storeId;
    var showChrono = false;
    var sections = ['cart'];
    if(typesenseConfig){
        var stopCounting = false;
        var isInitializedQuikView = false;
        var isInitializedAjaxCart = false;
        var svgSpriteUrl = require.toUrl('images/sprite-svg.svg');
        if(!$('#ax-quick-view-popup-wrapper').length){
            $('body').append(' <div id="ax-quick-view-popup-wrapper" class="row"></div>')
        }
        if(!$('#ax-ajax-cart-popup-wrapper').length){
            $('body').append(' <div id="ax-ajax-cart-popup-wrapper" class="row"></div>')
        }

        $(document).on("click", ".ax-quikview-btn", function () {
            if(!isInitializedQuikView){
                modalInitialize("ax-quick-view-popup-wrapper","ax-quick-view-popup-modal");
            }
            var id = $(this).attr('data-product-id');
            var catId = $(this).attr('data-cat-id');
            var hidePromoOldPrice = $(this).attr('data-hide-old-price');
            var showSpecialOffre = $(this).attr('data-show-special-offre');
            var showPriceRefMenzzo = $(this).attr('data-show-price-ref');


            var $this = $(this);
            var settings = {
                "url": "https://"+typesenseConfig.host_name+"/collections/"+typesenseConfig.prefix+"_products_"+storeId+"_category_"+catId+"/documents/search?q=*&query_by=product_id&exclude_fields=upsell_products&filter_by=product_id:="+id,
                "method": "GET",
                "timeout": 0,
                "headers": {
                    "X-TYPESENSE-API-KEY": typesenseConfig.api_key
                },
            };
            $.ajax(settings).done(function (response) {
                if(response.found != 0){
                    var product = response.hits[0].document;
                    var html =  generateQuickViewPopupHtml(showSpecialOffre,hidePromoOldPrice,showPriceRefMenzzo,product );
                    $('.ax-quick-view-popup-modal .modal-inner-wrap').css('width', '950px');
                    $('#ax-quick-view-popup-wrapper').html(html);
                    if(showChrono){
                        getBlocChronoTimer(product.product_id,product.chronoDateWithNewCalculatedPromo);
                    }
                    swiperInitialize();
                    $('#ax-quick-view-popup-wrapper').modal("openModal");
                }
            });
        });

        $(document).on("click", ".ax-quikview-tocart", function (e) {
            e.preventDefault();
            var id = $(this).attr('data-product-id');
            var productImage = $(this).attr('data-product-image');
            var productName = $(this).attr('data-product-name');
            var productUpsell = $(this).attr('data-product-upsell');
            var url = $(this).closest('form').attr('action');
            var form = $('#addToCart-form-' + id)[0];
            var data = new FormData(form);
            $.ajax({
                type: "POST",
                url: url,
                data: data,
                processData: false,
                contentType: false,
                cache: false,
                beforeSend: function () {
                    $('body').trigger('processStart'); // start loader
                },
                success: function (res) {
                    if (res == 'success') {
                        customerData.reload(sections, true);
                        $('#ax-quick-view-popup-wrapper').modal("closeModal");
                        showAjaxCartModal(id,productName,productImage,productUpsell);
                    } else {
                        location.reload();
                    }
                },
                error: function (res) {
                    if (res == 'error') {
                        $('#ax-quick-view-popup-wrapper').modal("closeModal");
                    }
                }
            }).done(function (data) {
                $('body').trigger('processStop');   // stop loader
                return true;
            });
        });

        if ($('.catalog-product-view').length) {
            $(document).on("click", "#product-addtocart-button,#ax-product-addtocart-button", function (e) {
                if (catalogAddToCartWidget) {
                    $(document).one('catalog:addToCartStatusUpdated', function (event, data) {
                        if(data.status) {
                            checkCheckoutCartUrl();
                        }
                    });
                }
            });
        }

        $(document).on("click", ".ax-ajaxcart-continue-shopping", function (e) {
            e.preventDefault();
            stopCounting = true;
            $('#ax-ajax-cart-popup-wrapper').modal("closeModal");
        });

        $(document).on("click", ".ax-ajaxcart-btn-view-cart", function (e) {
            e.preventDefault();
            stopCounting = true;
            var host = "https://"+ window.location.hostname;
            window.location.href=host+'/checkout/cart/';
        });
    }

    /**
     *
     * @param productId
     * @param productChrono
     */
    function getBlocChronoTimer(productId,productChrono){
        $('#bloc-chrono-timer-'+productId).countdown(productChrono, function (event) {
            var template = '<span class="d-flex flex-column justify-content-center align-items-center"><span><span>{D}</span><span>{D1}</span></span>{Days}</span> : '
                + '<span class="d-flex flex-column justify-content-center align-items-center"><span><span>{H}</span><span>{H1}</span></span>{Hours}</span> : '
                + '<span class="d-flex flex-column justify-content-center align-items-center"><span><span>{M}</span><span>{M1}</span></span>{Minutes}</span> : '
                + '<span class="d-flex flex-column justify-content-center align-items-center"><span><span>{S}</span><span>{S1}</span></span>{Secondes}</span>';

            var totalDays = event.offset.totalDays;
            var totalHours = event.offset.hours;
            var totalMinutes = event.offset.minutes;
            var totalSeconds = event.offset.seconds;

            var arrayDays = totalDays.toString().padStart(2, '0').split("",2);
            var arrayHours = totalHours.toString().padStart(2, '0').split("",2);
            var arrayMinutes = totalMinutes.toString().padStart(2, '0').split("",2);
            var arraySeconds = totalSeconds.toString().padStart(2, '0').split("",2);

            template = template.replace('{D}', ((typeof(arrayDays[0]) != "undefined") ? arrayDays[0] : '0'));
            template = template.replace('{D1}', ((typeof(arrayDays[1]) != "undefined") ? arrayDays[1] : '0'));
            template = template.replace('{H}', ((typeof(arrayHours[0]) != "undefined") ? arrayHours[0] : '0'));
            template = template.replace('{H1}', ((typeof(arrayHours[1]) != "undefined") ? arrayHours[1] : '0'));
            template = template.replace('{M}', ((typeof(arrayMinutes[0]) != "undefined") ? arrayMinutes[0] : '0'));
            template = template.replace('{M1}', ((typeof(arrayMinutes[1]) != "undefined") ? arrayMinutes[1] : '0'));
            template = template.replace('{S}', ((typeof(arraySeconds[0]) != "undefined") ? arraySeconds[0] : '0'));
            template = template.replace('{S1}', ((typeof(arraySeconds[1]) != "undefined") ? arraySeconds[1] : '0'));

            template = template.replace('{Days}', ((typeof(totalDays) != "undefined" && totalDays > 1) ? $t('days') : $t('day')));
            template = template.replace('{Hours}', ((typeof(totalHours) != "undefined" && totalHours > 1) ? $t('hours') : $t('hour')));
            template = template.replace('{Minutes}', ((typeof(totalMinutes) != "undefined" && totalMinutes > 1) ? $t('minutes') : $t('minute')));
            template = template.replace('{Secondes}', ((typeof(totalSeconds) != "undefined" && totalSeconds > 1) ? $t('seconds') : $t('second')));
            return $(this).html(template);
        });
    }
    /**
     *
     * @param product
     * @returns {string}
     */
    function generateQuickViewPopupHtml(showSpecialOffre,hidePromoOldPrice,showPriceRefMenzzo,product,show3XBlock)
    {
        /***Product Images carousel**/
        var productImagesHtml = '';
        var productImagesThumbsHtml = '';
        let images = typeof product.media_gallery_images !== "undefined" ? product.media_gallery_images : '[]';
        var productImages = JSON.parse(images);
        if (productImages.length > 0) {
            productImagesHtml += `<div class="ax-typesense-swiper">`;
            productImagesHtml += `<div class="carousel-ax-typesense-swiper">`;
            let lazySrcset = "https://cdn-img.menzzo.com/media/lazysizes/menzzo_big.jpg?twic=v1/cover=430x430"
            productImages.forEach(image => {
                let imageUrl = image.url + '?twic=v1/resize=430';
                let imageWebpUrl = appendParameter(image.url, 'fm=webp') + '&twic=v1/resize=430';

                let picture = ''
                if (isSafari13()) {
                    let relatedImageWebpUrl2 = image.url +  '?twic=v1/resize=430'
                    picture = `<picture>
                      <img class="lazyload" src="${relatedImageWebpUrl2}"  width="430" height="430" alt="image"></picture>`
                } else {
                    picture = `<picture>
                      <source data-srcset="${imageWebpUrl}" type="image/webp">
                      <img class="lazyload" srcset="${lazySrcset}"  src="${imageUrl}"  width="430" height="430" alt="image"></picture>`
                }

                productImagesHtml += `<div class="carousel-cell">
                      ${picture}</div>`;
            });
            productImagesHtml += `</div>`;
            productImagesHtml += `</div>`;


            productImagesThumbsHtml += `<div class="ax-typesense-thumbs">`;
            productImagesThumbsHtml += `<div class="carousel-nav carousel-ax-typesense-thumbs">`;
            let lazySrcsetNav = "https://cdn-img.menzzo.com/media/lazysizes/menzzo_big.jpg?twic=v1/cover=100x100"

            productImages.forEach(thumb => {
                let thumbUrl = thumb.url + '?twic=v1/resize=100';
                let thumbWebpUrl = appendParameter(thumb.url, 'fm=webp') + '&twic=v1/resize=100';
                let navPic = ''
                if (isSafari13()) {
                    navPic = `<picture>
                <img src="${thumbWebpUrl}" width="100" height="100"></picture>`
                } else {
                    navPic = `<picture>
                <source data-srcset="${thumbWebpUrl}" type="image/webp">
                <img class="lazyload" srcset="${lazySrcsetNav}"  src="${lazySrcsetNav}" width="100" height="100" alt="thumb"></picture>`
                }
                productImagesThumbsHtml += `<div class="carousel-cell">
                ${navPic}</div>`;
            });
            productImagesThumbsHtml += `</div>`;
            productImagesThumbsHtml += `</div>`;
        }
        var html = `<div class="col-md-6">`;
        if ($(window).width() < 1025) {
            html += `<a href="${product.url}" class="product-name mb-3 text-center d-block" data-product-id='${product.product_id}' >${product.name}</a>`;
        }

        html += `${productImagesHtml}`;
        html += `${productImagesThumbsHtml}`;
        html += `<div class="typesense-product-url"><a class="button complete" href="${product.url}">${$t('Complete product sheet')}</a></div>`;
        html += `</div>`;

        /** second side (product info)**/
        var menzzoRefPrice = $t('Menzzo reference price: <strong>%1</strong>');
        var marketingStrategyInfo = '';
        var priceClass = 'justify-content-end bloc-right';
        var dropshipClass = 'menzzo-design';
        if (product.brand != 'Menzzo') {
            dropshipClass = 'dropship-design';
            priceClass = 'justify-content-start bloc-left';
            hidePromoOldPrice = 1;
        }

        if (product.brand == 'Menzzo' && showSpecialOffre == 1) {
            if (product.qtyWithNewCalculatedPromo && product.qtyWithNewCalculatedPromo !== 'undefined' && product.avail_class == 'in-stock') {
                var qtyWithNewCalculatedPromo = JSON.parse(product.qtyWithNewCalculatedPromo);
                marketingStrategyInfo += `<div class="special-offer-stock d-flex flex-column justify-content-center align-items-center py-2 px-3">`;
                marketingStrategyInfo += `<div class="title"><b>${$t('Special offer')}</b><span>${$t('On a limited stock of items')}</span></div>`;
                marketingStrategyInfo += `<div class="content-special-offer d-flex">${$t('More than')}`;
                marketingStrategyInfo += `<span class="mx-2"><span>${qtyWithNewCalculatedPromo[0]}</span><span>${qtyWithNewCalculatedPromo[1]}</span><span>${qtyWithNewCalculatedPromo[2]}</span></span>`;
                marketingStrategyInfo += `<span class="d-flex flex-column b-text align-items-start">${$t('item(s) left at this price')}<span>${$t('after that it\'s more expensive !')}</span></span>`;
                marketingStrategyInfo += `</div>`;
                marketingStrategyInfo += `</div>`;
            } else if (product.chronoDateWithNewCalculatedPromo && product.chronoDateWithNewCalculatedPromo !== 'undefined' && product.avail_class == 'out-of-stock') {
                marketingStrategyInfo += `<div class="head-flash-sale d-flex flex-column justify-content-center align-items-center py-2 px-3">`;
                marketingStrategyInfo += `<div class="title">${$t('Special price <b>preorder</b>')} <span> ${$t('The sooner you buy, the more you save!')}</span></div>`;
                marketingStrategyInfo += `<div class="text-center w-100 mb-3 title-chrono">${$t('Price increase in')}</div>`;
                marketingStrategyInfo += `<div class="bloc-chrono d-flex" id="bloc-chrono-timer-${product.product_id}">`;
                marketingStrategyInfo += `<span class="d-flex flex-column justify-content-center align-items-center"><span><span>0</span><span>0</span></span>${$t('day')}</span> :`;
                marketingStrategyInfo += `<span class="d-flex flex-column justify-content-center align-items-center"><span><span>0</span><span>0</span></span>${$t('hour')}</span> :`;
                marketingStrategyInfo += `<span class="d-flex flex-column justify-content-center align-items-center"><span><span>0</span><span>0</span></span>${$t('minute')}</span> :`;
                marketingStrategyInfo += `<span class="d-flex flex-column justify-content-center align-items-center"><span><span>0</span><span>0</span></span>${$t('second')}</span>`;
                marketingStrategyInfo += `</div></div>`;
                showChrono = true;
            }
        }


        var discountClass = "";
        var axDisplayPromo = typeof product.ax_display_promo !== "undefined" ? product.ax_display_promo : false;
        if (axDisplayPromo) {
            discountClass = "ax-special-price discount-29";
        }
        var priceHtml = `<span class='product-price final-price d-flex align-items-center ${discountClass}'>${product.price} €</span>`;
        if (product.has_special_price) {
            if (hidePromoOldPrice == 1 || parseInt(product.discount) <= 5) {
                if (parseInt(product.discount) >= 29 || true) {
                    discountClass = "ax-special-price discount-29";
                }
                priceHtml = `<span class='product-price final-price d-flex align-items-center ${discountClass}'>${product.special_price} €</span>`;
            } else if (hidePromoOldPrice == 0 && parseInt(product.discount) > 5) {
                priceHtml = `<div class="special-price-wrap">`;
                if (showPriceRefMenzzo == 0) {
                    priceHtml += `<div class="old-price d-flex align-items-center justify-content-start">${product.regular_price} €</div>`;
                }
                priceHtml += `<div class="special-price d-flex align-items-center"><span class="discount-percent">-${product.discount}</span>
                             <span class="product-price final-price d-flex align-items-center">${product.special_price} €</span></div>`;
                priceHtml += `</div><div>`;
                if (showPriceRefMenzzo == 1) {
                    priceHtml += `<span class="menzzo-old-price">${menzzoRefPrice.replace('%1', product.price + ' €')}</span>`;
                }
                priceHtml += `</div>`;
            }

        }
        /***Related Products**/
        var relatedProductsHtml = '';
        let related = typeof product.related_products !== "undefined" ? product.related_products : '[]';
        var relatedProducts = JSON.parse(related);
        if (relatedProducts.length > 0) {
            var relatedProductsLabel = $t('Other colors');
            relatedProductsHtml += `<div class="ax-product-related-block"><div class="block-related-title"><strong>${relatedProductsLabel}</strong></div>`;
            relatedProductsHtml += `<div class="block-related-content"><ol>`;
            let lazySrcset = "https://cdn-img.menzzo.com/media/lazysizes/menzzo_big.jpg?twic=v1/cover=50x50"
            relatedProducts.forEach(related => {
                let relatedImageUrl = related.image;
                let relatedImageWebpUrl = appendParameter(related.image, 'fm=webp');

                let picture = ''
                if (isSafari13()) {
                    let relatedImageWebpUrl = related.image;
                    picture = `
                            <picture>
                                <img src="${relatedImageWebpUrl}" width="50" height="50" alt="${related.name}">
                            </picture>`
                } else {
                    picture = `<picture>
                       <source data-srcset="${relatedImageWebpUrl}"  data-srcset="${relatedImageWebpUrl}" type="image/webp">
                           <img class="lazyload"  data-src="${lazySrcset}" src="${lazySrcset}"  width="50" height="50" alt="${related.name}">
                   </picture>`
                }

                relatedProductsHtml += `<li class="product-related-color" id="product-related-${related.id}">
                                            <a href="${related.url}" class="color-img">
                                            ${picture}
                                            </a>
                                        </li>`;
            });
            relatedProductsHtml += `</ol></div>`;
        }

        var ecoTax = $t('Including <strong>%1</strong> eco-share.');
        var resultEcoTax =  ecoTax.replace('%1', product.ecotax);
        var ecoHtml =` <span class="eco-tax-text">
        ${resultEcoTax}</span>`;

        var pgConstant = $t('Price generally observed: <strong>%1</strong>');
        var reultPgConstant =  pgConstant.replace('%1', product.prixGeneralconstant);
        var prixGeneralconstantHtml =`<span class="price-generally-observed">
            ${reultPgConstant} €
        </span>`;

        /*** Crosssell Products**/
        var crosssellProductsHtml = '';
        let crosssell = typeof product.crosssell_products !== "undefined" ? product.crosssell_products : '[]';
        var crosssellProducts = JSON.parse(crosssell);
        if (crosssellProducts.length > 0) {
            var crosssellProductsLabel = $t('More Choices:');
            crosssellProductsHtml += `<div class="ax-product-crossell-block"><div class="block-crossell-title"><strong>${crosssellProductsLabel}</strong></div>`;
            crosssellProductsHtml += `<div class="block-crossell-content"><ol>`;
            crosssellProducts.forEach(crosssell => {
                crosssellProductsHtml +=
                    `<li class="product-crosssell-format" id="product-crossell-${crosssell.id}">
                                            <a href="${crosssell.url}" class="format-label">
                                            <span>${crosssell.label}</span>

                                            </a>
                                        </li>`;
            });
            crosssellProductsHtml += `</ol></div>`;
        }
        html += `<div class="col-md-6">`;
        if ($(window).width() > 1024) {
            html += `<a href="${product.url}" class="product-name mb-3" data-product-id='${product.product_id}' >${product.name}</a>`;
        }
        html += `<div class="ax-product-price-block ${dropshipClass}">`;
        html += `${marketingStrategyInfo}`;
        html += `<div class="section-price d-flex align-items-center justify-content-start">`;
        html += `<div className="product-info-price">`;
        if(product.brand != 'Menzzo') {
            html +=`<strong class="strong-uppercase">${$t('limited quantity')} !</strong>`;
        }
        html += `${priceHtml}`;
        if (product.ecotax && product.ecotax > 0) {
            html += `${ecoHtml}`;
        }
        if (product.brand && product.brand == 'AsirGroup' && product.prixGeneralconstant){
            html += `${prixGeneralconstantHtml}`;
        }
        html += `</div>`;
        html += `</div>`;


        /** add to cart block**/

        let addToCart = typeof product.add_to_cart !== "undefined" ? product.add_to_cart : '[]';
        var addToCartData = JSON.parse(addToCart);

        let aiUpsellData = typeof product.ai_upsell_products !== "undefined" ? product.ai_upsell_products : '[]';

        html += `<div class="ax-product-addToCart-block">`;
        html += `<form id="addToCart-form-${product.product_id}" data-role="tocart-form" data-product-sku="${product.sku}" action="${addToCartData.action}" method="post">`;
        html += `<input type="hidden" name="product" value="${product.product_id}">`;
        html += `<input type="hidden" name="qty" value="1">`;
        html += `<input type="hidden" name="urlAction" value="axTypesenseAddToCart">`;
        let productOptions = typeof product.product_options !== "undefined" ? product.product_options : '[]';
        var productOptionsData = JSON.parse(productOptions);
        $.each(productOptionsData, function (key, value) {
            html += `<input type="hidden" name="options[${key}]" value="${value}">`;
        });

        html += `<div class="ax-product-cart-box fieldset d-flex justify-content-center align-items-center my-4">`;

        html += `<div class="field qty-wrap">`;
        html += `<select type="number" name="qty" min="0" value="1" title="${$t('Qty')}" class="input-text qty">`;
        html += `<option value="1">1</option>`;
        html += `<option value="2">2</option>`;
        html += `<option value="3">3</option>`;
        html += `<option value="4">4</option>`;
        html += `<option value="5">5</option>`;
        html += `<option value="6">6</option>`;
        html += `<option value="7">7</option>`;
        html += `<option value="8">8</option>`;
        html += `<option value="9">9</option>`;
        html += `<option value="10">10</option>`;

        html += `</select>`;
        html += `</div>`;
        html += `<div class="actions">`;
        html += `<button type="submit" title="${$t('Add to Cart')}" data-product-id='${product.product_id}' data-product-image='${product.image}' data-product-name='${product.name}' data-product-upsell='${aiUpsellData}' class="action tocart primary ax-quikview-tocart">`;
        html += `<span>${$t('Add to Cart')}</span>`;
        html += `</button>`;
        html += `</div>`;
        html += `</div>`;


        html += `</form>`;
        html += `</div>`;

        html += `<div class="product-dispo-wrapper d-flex justify-content-between align-items-center">`;
        html += `<div class="ax-product-dispo-block  d-flex align-items-center flex-wrap">`;
        html += `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M342.4 32C367.7 32 390.6 46.9 400.9 70.01L442.5 163.6C446.1 171.8 448 180.6 448 189.6V416C448 451.3 419.3 480 384 480H64C28.65 480 0 451.3 0 416V189.6C0 180.6 1.879 171.8 5.516 163.6L47.11 70.01C57.38 46.89 80.3 32 105.6 32H342.4zM342.4 80H248V160H388.4L357 89.5C354.5 83.72 348.7 80 342.4 80V80zM400 208H48V416C48 424.8 55.16 432 64 432H384C392.8 432 400 424.8 400 416V208zM59.64 160H200V80H105.6C99.27 80 93.54 83.72 90.97 89.5L59.64 160z"/>
                </svg>`;
        html += `<div class="product-availability">`;

        outOfStockLabel = '';
        // if (product.avail_class == 'out-of-stock') {
        //     var outOfStockLabel = `<span class="black">${$t('Arrival in')}</span> `;
        // }
        html += `<strong class="${product.avail_class}">${outOfStockLabel} ${product.dispo}</strong></div>`;
        html += `</div>`;

        if (product.free_shipping) {
            html += `<div class="bigBuyProd">`;
            html += `<span class="develaryBlock">`;
            html += `<svg class="icon sprite-svg-icon deliveryTruck">`;
            html += `  <use xlink:href="${svgSpriteUrl}#delivery_truck"></use>`;
            html += `</svg>`;
            html += `<span class="bigbuy-text">${$t('Free delivery')}</span>`;
            html += `</span>`;
            html += `</div>`;
        }
        html += `</div>`;

        if (product.shippingBestAmount && product.shippingBestAmount !== "undefined" && !product.free_shipping) {
            var shippingInfo = `<strong class="11">${$t('Shipping')}:</strong> ${$t('from')}`;
            if (product.brand == 'AsirGroup' && product.asirShippingInfo !== "undefined" && !(product.ship_from_asir_internal_stock)) {
                shippingInfo = `<strong class="22">${product.asirShippingInfo}</strong> ${$t('from')}`;
            } else {
                shippingInfo = `<strong class="22">${product.menzzo_shipping_info}</strong> ${$t('from')}`;
            }
            shippingInfo += ` <strong class="red">${product.shippingBestAmount}</strong>`;
            html += `<div class="product-shipping-info">${shippingInfo}</div>`;
        }


        // if(product.bestShipAmount) {
        //     html += `<div class="product-shipping-amount">`;
        //     html += `<strong class="price">${product.bestShipAmount}</strong>`;
        //     html += `</div>`;
        // }

        html += `${relatedProductsHtml}`;

        html += `${crosssellProductsHtml}`;

        html += `</div>`;

        return html;
    }

    function hideMessage() {
        customerData.set('messages', {messages: ""});
    }
    function isSafari13() {
        var ua = navigator.userAgent.toLowerCase();

        // Check if the browser is Safari
        var isSafari = /^((?!chrome|android).)*safari/i.test(ua);

        if (isSafari) {
            // Extract version information for Safari
            var match = ua.match(/version\/(\d+)\./);
            if (match && parseInt(match[1]) === 13) {
                return true;  // It's Safari 13
            }
        }
        return false;
    }

    /**
     *
     */
    function swiperInitialize(){
        require(['require', 'flickity'], function(require, _Flickity) {
            require(['flickity-as-nav-for/as-nav-for'], function(Flickity) {

                var typesenseSwiper = new Flickity(`.carousel-ax-typesense-swiper`, {
                    cellAlign: 'left',
                    pageDots: true,
                    lazyLoad: 1,
                    on: {
                        // Event handler for when Flickity is initialized
                        ready: function () {
                            this.resize()
                        }
                    }
                });


                var nav = new Flickity('.carousel-nav', {
                    asNavFor: '.carousel-ax-typesense-swiper',
                    contain: true,
                    pageDots: false,
                    prevNextButtons: false,
                    on: {
                        // Event handler for when Flickity is initialized
                        ready: function () {
                        this.resize()
                    }
                }
            });

                if (isSafari13()) {
                    $('.carousel-ax-typesense-swiper').find('.flickity-viewport').css('width', '450px');
                    $('.carousel-ax-typesense-thumbs').find('.flickity-viewport').css('width', '450px');
                    typesenseSwiper.resize()
                    nav.resize()
                }
            return typesenseSwiper;
            });
        });
    }

    /**
     *
     * @param className
     */
    function modalInitialize(id,className){
        $('#'+id).modal({
            autoOpen: false,
            clickableOverlay: false,
            innerScroll: true,
            responsive: true,
            modalClass: className,
            type: 'popup',
            closed: function () {
            },
            opened: function () {
            }
        });

        if(id == "ax-ajax-cart-popup-wrapper"){
            isInitializedAjaxCart = true;
        }else if(id == "ax-quick-view-popup-wrapper"){
            isInitializedQuikView = true;
        }
    }


    // function initializeAiUpsellSwiper(productId){
    //     var aiUpsellSlider = new Swiper("#ai-upsell-slider-"+productId, {
    //         direction: 'horizontal',
    //         navigation: {
    //             nextEl: ".swiper-button-next",
    //             prevEl: ".swiper-button-prev",
    //         },
    //         spaceBetween: 10,
    //         loop: false,
    //         breakpoints: {
    //             0: {
    //                 slidesPerView: 2
    //             },
    //             768: {
    //                 slidesPerView: 3
    //             }
    //         },
    //     });
    //     return aiUpsellSlider;
    // }

    function showAjaxCartModal(productId,productName,productImage,productUpsell){
        if(!isInitializedAjaxCart){
            modalInitialize("ax-ajax-cart-popup-wrapper","ax-ajax-cart-popup-modal");
        }
        var html =  generateAjaxCartHtml(productId,productName,productImage,productUpsell);
        $('.ax-ajax-cart-popup-modal .modal-inner-wrap').css('width', '600px');
        $('#ax-ajax-cart-popup-wrapper').html(html);
        // initializeAiUpsellSwiper(productId);
        carouselUpsellPopup();
        $('#ax-ajax-cart-popup-wrapper').modal("openModal");
        // autoCloseAxAjaxPopup();
    }

    function generateAjaxCartHtml(productId,productName,productImage,productUpsell){
        let aiProductUpsell = typeof productUpsell !== "undefined" ? productUpsell : '[]';
        var aiProductUpsellData = JSON.parse(aiProductUpsell.replace(/&quot;/g,'"'));
        var html = '<div class="ax-product-item-photo">';
        let productImageWebpUrl = appendParameter(productImage, 'fm=webp');
        let lazySrcset = "https://cdn-img.menzzo.com/media/lazysizes/menzzo_big.jpg?twic=v1/cover=240x240"

        let picture = ''
        if (isSafari13()) {
            picture = `<picture><img src="${productImageWebpUrl}" width="240" height="240"></picture>`
        } else {
            picture = `<picture><source data-srcset="${productImageWebpUrl}" type="image/webp"><img class="lazyload"  srcset="${lazySrcset}" src="${lazySrcset}" width="240" height="240" alt="image"></picture>`
        }

        html += picture;
        html += '</div>';
        html += '<div class="ax-product-item-name">';
        html += '<div class="ax-ajaxcart-product-title">'+productName+'</div>';
        html += '<span class="ax-ajaxcart-product-message">'+$t("has been added to your shopping cart !")+'</span>';
        html += '</div>';


        html += '<div class="ax-ajaxcart-actions">';
        html += '<button type="submit" title="Continue shopping" class="action primary ax-ajaxcart-continue-shopping">'+$t("Continue shopping")+'</button>';
        html += '<button type="submit" title="View Cart" class="action primary ax-ajaxcart-btn-view-cart">'+$t("View Cart")+'</button>';
        html += '</div>';

        if (aiProductUpsellData.length > 0) {
            let flexClass = aiProductUpsellData.length < 3 ? 'justify-content-center' : 'justify-content-start';
            html += `<div class="ai-product-upsell" >`;
            html += `<div class="upsell-title"><strong>` + $t("To complete your selection") + `</strong></div>`;
            html += `<div class="swiper-ai-upsell" id="ai-upsell-slider-${productId}">`;


                html += `<div class="swiper-ai-upsell" id="ai-upsell-slider-${productId}">`;
                    html += `<div class="carousel-upsell-popup">`;
                    aiProductUpsellData.forEach(upsell => {
                        let upsellImageWebpUrl = appendParameter(upsell.image, 'fm=webp');
                        html += `<div class="product-upsell-data carousel-cell" id="product-upsell-${upsell.id}">`;
                        html += `<div class="product-upsell-image">`;
                        let pic = ''
                        if (isSafari13()) {
                            pic = `<picture><img src="${upsellImageWebpUrl}" width="120" height="120"/></picture>`
                        } else {
                            pic = `<picture><source data-srcset="${upsellImageWebpUrl}" type="image/webp"><img  srcset="${lazySrcset}" src="${lazySrcset}" class="lazyload" alt="${upsell.name}" width="120" height="120"/></picture>`
                        }

                        html += `<a href="${upsell.url}" class="upsell-url">${pic}</a>`;
                        html += `</div>`;
                        html += `<div class="product-upsell-name">`;
                        html += `<a  href="${upsell.url}" class="upsell-url"><span>${upsell.name}</span></a>`;
                        html += `</div>`;
                        html += `<div class="product-upsell-price">`;
                        let htmlPrice = `<span class="price" >${upsell.price}</span>`;
                        if (upsell.has_special_price) {
                            htmlPrice = `
                              <span class="line-through">${upsell.regular_price}</span>
                                <span class="btn-promo">-${upsell.discount}</span>
                                <span class="price-promo">${upsell.special_price}</span>`;
                        }
                        html += `${htmlPrice}`;
                        html += `</div>`;
                        html += `</div>`;
                    });
                    html += `</div>`;
                html += `</div>`;
        }
        return html;
    }

    function autoCloseAxAjaxPopup() {
        var host = "https://"+ window.location.hostname,
            time = parseInt(7);
        var countdown = setInterval(function () {
            if(stopCounting){
                clearInterval(countdown);
                stopCounting = false;
            }
            time = time - 1;
            if (time === 0) {
                clearInterval(countdown);
                $('#ax-ajax-cart-popup-wrapper').modal("closeModal");
                window.location.href=host+'/checkout/cart/';
            }
            $('.ax-ajaxcart-countdown').text('(' + time + 's)');
        }, 1000);
    }

    function checkCheckoutCartUrl(){

        let id = $('input[name=item]').val();
        var settings = {
            "url": "https://"+typesenseConfig.host_name+"/collections/"+typesenseConfig.prefix+"_products_"+storeId+"_category_"+typesenseConfig.default_categoryId+"/documents/search?q=*&query_by=product_id&exclude_fields=upsell_products&filter_by=product_id:="+id,
            "method": "GET",
            "timeout": 0,
            "headers": {
                "X-TYPESENSE-API-KEY": typesenseConfig.api_key
            },
        };

        $.ajax(settings).done(function (response) {
            if(response.found !== 0){
                var product = response.hits[0].document;
                var productId = product.product_id;
                var productName = product.name;
                var productImage = product.image;
                var productUpsell = typeof product.ai_upsell_products !== "undefined" ? product.ai_upsell_products : '[]';
                showAjaxCartModal(productId,productName,productImage,productUpsell);
            }
        });

    }

    function carouselUpsellPopup(){
        require(['require', 'flickity'], function(require, Flickity) {
            var flickityUpsellPopup = new Flickity('.carousel-upsell-popup', {
                pageDots: false,
                cellAlign: 'left',
                lazyLoad: 1,
                prevNextButtons: false,
                imagesLoaded: true,
                contain: true,
                wrapAround: true,
                groupCells: true,
                on: {
                    // Event handler for when Flickity is initialized
                    ready: function() {
                        this.resize()
                    }
                }
            });
            return flickityUpsellPopup
        });
    }

});

